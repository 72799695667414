// colors

$primary: #224FF0;
$secondary: #FFD433;
$muted: #262626;
$text: #828384;

// sizes

$desktop-width: 1420px;
$search-hiding: 1020px;
$tablet-width: 834px;
$mobile-width: 375px;