@import '../variablesNew.scss';

.main {
  background-color: #f8fbfe;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0px 30px 68px 30px;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: $desktop-width) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: $tablet-width) {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 18px;
  }

  .box {
    width: 100%;
    display: flex;
    background-color: white;
    flex-direction: row;
    padding: 44px;
    box-shadow: 2px 0px 10px 0px #4C4B4B0D;
    border-radius: 16px;

    @media screen and (max-width: $desktop-width) {
      padding: 22px;
    }

    @media screen and (max-width: $tablet-width) {
      padding: 18px;
    }
  }
  
  .section1 {
    margin-top: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: $desktop-width) {
      gap: 12px;
    }

    @media screen and (max-width: $tablet-width) {
      flex-direction: column;
    }

    .box1 {
      max-width: 940px;
      height: 372px;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: $desktop-width) {
        height: 264px;
        max-width: none;
      }

      @media screen and (max-width: $tablet-width) {
        height: auto;
      }
  
      h1 {
        font-size: 48px;
        line-height: 64px;
        margin: 0;
        color: $muted;

        @media screen and (max-width: $desktop-width) {
          font-size: 28px;
          line-height: 36px;
          font-weight: 400;
        }
      }
  
      p {
        color: $text;
        font-size: 25px;
        line-height: 32px;

        @media screen and (max-width: $desktop-width) {
          font-size: 20px;
          line-height: 26px;
        }
      }
  
      .actions {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-top: auto;

        @media screen and (max-width: $tablet-width) {
          flex-wrap: wrap;
          justify-content: center;
        }
  
        .filledBtn {
          max-width: 200px;
        }
        
        .outlinedBtn {
          max-width: 270px;
        }
      }
    }

    .box2 {
      max-width: 460px;
      height: 372px;
      background-size: cover;
      background-image: url('../../public/images/photo1.png');
      transform: scaleX(-1);

      @media screen and (max-width: $desktop-width) {
        max-width: 263px;
        height: 264px;
      }

      @media screen and (max-width: $tablet-width) {
        width: 100%;
        max-width: none;
        max-height: 206px;
      }
    }
  }

  .section2 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 64px;

    .box3 {
      max-width: $desktop-width;
      justify-content: space-between;

      @media screen and (max-width: $desktop-width) {
        flex-direction: column;
        gap: 24px;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 670px;

        @media screen and (max-width: $desktop-width) {
          max-width: none;
        }

        .infoHead {
          display: flex;
          flex-direction: column;
          gap: 12px;

          @media screen and (max-width: $desktop-width) {
            max-width: none;
            justify-content: space-between;
            flex-direction: row-reverse;
          }
        }

        h2 {
          font-size: 32px;
          line-height: 44px;
          color: $primary;
          margin: 0;
        }
        
        p {
          color: $text;
          font-size: 24px;
          line-height: 32px;
        }
      }

      .stats {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        max-width: 448px;

        @media screen and (max-width: $desktop-width) {
          max-width: none;
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        @media screen and (max-width: $desktop-width) {
          grid-template-columns: 1fr 1fr;
        }

        .stat {
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          @media screen and (max-width: $desktop-width) {
            padding: 4px;
          }

          p {
            color: $text;
          }

          h5 {
            margin: 0;
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .section3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    margin-top: 64px;
    max-width: $desktop-width;
    gap: 20px;

    h2 {
      font-size: 32px;
      line-height: 44px;
      margin: 0;
      max-width: 700px;
      color: $muted;

      @media screen and (max-width: $tablet-width) {
        font-size: 28px;
        line-height: 34px;
        font-weight: 500;
        padding: 0 20px;
      }
    }

    .steps {
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: $desktop-width) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      @media screen and (max-width: $tablet-width) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      .step {
        padding: 20px;
        background-color: white;
        box-shadow: 2px 0px 10px 0px #4C4B4B0D;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 329px;
        width: 100%;

        @media screen and (max-width: $desktop-width) {
          max-width: none;
        }

        @media screen and (max-width: $tablet-width) {
          padding: 18px;
          gap: 8px;
        }

        .filledBtn {
          max-width: 289px;
        }

        .stepHead {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          h3 {
            font-size: 48px;
            line-height: 64px;
            color: $text;
            margin: 0;

            @media screen and (max-width: $tablet-width) {
              font-size: 32px;
              line-height: 48px;
              font-weight: 700;
            }
          }
        }

        .stepBody {
          display: flex;
          flex-direction: column;
          gap: 8px;
          color: $text;

          h3 {
            font-size: 24px;
            line-height: 32px;
            color: black;
            font-weight: 400;
            margin: 0;
          }

          p {
            color: $text;
          }
        }
      }
    }
  }
  
  .section4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    margin-top: 64px;
    max-width: $desktop-width;
    gap: 8px;
  }

  .section5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    margin-top: 64px;
    max-width: $desktop-width;
    gap: 20px;

    .box {
      gap: 96px;

      @media screen and (max-width: $desktop-width) {
        flex-direction: column;
        gap: 40px;
      }
    }

    .map {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h2 {
        font-size: 32px;
        color: $muted;
        line-height: 44px;

        @media screen and (max-width: $tablet-width) {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
        }
      }

      svg {
        align-self: center;
        width: 525px;

        @media screen and (max-width: $tablet-width) {
          width: 100%;
          height: 100%;
        }
      }
    }

    .careinfo {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h2 {
        font-size: 32px;
        color: black;
        line-height: 44px;

        @media screen and (max-width: $tablet-width) {
          font-size: 28px;
          font-weight: 500;
          line-height: 32px;
        }
      }

      .careRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media screen and (max-width: $tablet-width) {
          grid-template-columns: 1fr;
        }

        .careBlock {
          display: flex;
          flex-direction: column;
          gap: 12px;

          h3 {
            font-size: 24px;
            line-height: 32px;
            font-weight: 400;
            color: $muted;
          }

          p {
            line-height: 24px;
            color: #969696;
          }
        }
      }
    }
  }

  .section6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    margin-top: 64px;
    max-width: $desktop-width;
    gap: 20px;

    .box {
      display: grid;
      grid-template-columns: 1fr 2fr;

      @media screen and (max-width: $desktop-width) {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      @media screen and (max-width: $tablet-width) {
        height: auto;
        padding: 10px 0px;
      }
    }

    .qaHead {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: $desktop-width) {
        flex-direction: row;
      }

      @media screen and (max-width: $tablet-width) {
        padding: 0px 18px;
      }
  
      h2 {
        font-size: 32px;
        line-height: 44px;

        @media screen and (max-width: $tablet-width) {
          font-size: 28px;
          font-weight: 500;
          line-height: 44px;
        }
      }

      svg {
        transform: rotate(270deg);
      }
    }

    .qaBody {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .accordion {
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        padding: 18px;
        transition: all 0.3s ease;
        background-color: white;
        border: 0;
        font-size: 16px;
        cursor: pointer;
        line-height: 24px;
        justify-content: space-between;
        width: 100%;

        &:focus-visible {
          outline: 1px solid black;
        }
          
        &:hover {
          box-shadow: 0px 0px 14px 0px #0000001F;
        }
        
        section {
          background-color: white;
          border: 0;
          border-radius: 16px;
          all: unset;
          font-size: 24px;
          cursor: pointer;
          line-height: 32px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          text-align: left;
        }

        * {
          overflow: hidden;
          color: black;
        }

        .accordion_item {
          display: grid;
          grid-template-rows: 1fr;
          transition: all 0.3s ease;
          text-align: left;
        }

        svg {
          transition: all 0.3s ease;
          overflow: visible;
        }

      }
    }
  }

  .section7 {
    margin-top: 64px;
    width: -webkit-fill-available;
    width: -moz-available;
    max-width: $desktop-width;

    @media screen and (max-width: $desktop-width) {
      display: flex;
      flex-direction: row;
      overflow: auto;
      max-width: none;
      padding: 20px 0;
    }

    @media screen and (max-width: $desktop-width) and (min-width: $tablet-width) {
      margin-left: -30px;
      margin-right: -15px;
    }

    @media screen and (max-width: $tablet-width) {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      gap: 20px;
    }

    .reviews {
      display: grid;
      grid-template-columns: 1.31fr 2fr 2fr;
      gap: 20px;
      overflow: auto;

      @media screen and (max-width: $tablet-width) {
        display: flex;
      }

      @media screen and (max-width: $desktop-width) and (min-width: $tablet-width) {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .box {
      flex-direction: column;
      gap: 12px;
    }

    .review1 {
      min-width: 340px;

      @media screen and (max-width: $desktop-width) and (min-width: $tablet-width) {
        margin-right: 15px;
      }
    }

    .review2 {
      min-width: 521px;
    }

    .reviewer {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      .role {
        color: #999999;
        font-size: 14px;
      }
    }

    .leaveReview {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-left: 56px;

      @media screen and (max-width: $desktop-width) {
        min-width: 460px;
        margin-left: 30px;
      }

      @media screen and (max-width: $tablet-width) {
        display: none;
      }

      h3 {
        font-size: 48px;
        line-height: 64px;

        span {
          color: $primary;
        }

        @media screen and (max-width: $tablet-width) {
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;
        }
      }
      
      p {
        color: $muted;
        font-size: 24px;
        line-height: 32px;
      }

      .outlinedBtn {
        max-width: 180px;
        justify-content: center;
        margin-top: 16px;
      }
    }

    .leaveReview2 {
      display: none;
      flex-direction: column;
      gap: 16px;
      margin-left: 5px;

      @media screen and (max-width: $tablet-width) {
        display: flex;
        margin-left: 18px;
      }

      h3 {
        font-size: 48px;
        line-height: 64px;

        span {
          color: $primary;
        }

        @media screen and (max-width: $tablet-width) {
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;
        }
      }
      
      p {
        color: $muted;
        font-size: 22px;
        line-height: 32px;
      }

      .outlinedBtn {
        max-width: 180px;
        justify-content: center;
      }
    }
  }

  .section8 {
    margin-top: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: $desktop-width) {
      gap: 12px;
    }

    @media screen and (max-width: $tablet-width) {
      flex-direction: column;
    }

    .box1 {
      max-width: 940px;
      height: 372px;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: $desktop-width) {
        height: 264px;
        max-width: none;
      }

      @media screen and (max-width: $tablet-width) {
        height: auto;
        max-width: none;
      }
  
      h2 {
        font-size: 48px;
        line-height: 64px;
        margin: 0;
        color: $muted;

        @media screen and (max-width: $desktop-width) {
          font-size: 36px;
          line-height: 52px;
        }

        @media screen and (max-width: $tablet-width) {
          font-size: 32px;
          line-height: 40px;
        }
      }
  
      p {
        color: $text;
        font-size: 25px;
        line-height: 32px;

        @media screen and (max-width: $desktop-width) {
          font-size: 18px;
          line-height: 24px;
        }
      }
  
      .actions {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-top: auto;

        @media screen and (max-width: $tablet-width) {
          flex-wrap: wrap;
          justify-content: center;
        }
  
        .filledBtn {
          max-width: 200px;
        }
        
        .outlinedBtn {
          max-width: 270px;
        }
      }
    }

    .box2 {
      max-width: 460px;
      height: 372px;
      background-size: cover;
      background-image: url('../../public/images/photo2.png');
      background-position: center center;
      transform: scaleX(-1);

      @media screen and (max-width: $desktop-width) {
        height: 220px;
        max-width: 263px;
      }

      @media screen and (max-width: $tablet-width) {
        max-width: none;
      }
    }
  }
}

.destroyMe {
  width: 100%;
  margin-top: 20px;
  max-width: 1420px;
  display: flex;
  justify-content: flex-end;

  h2 {
    font-size: 32px;
    line-height: 44px;
    font-weight: 500;
    color: #FFD433;
    max-width: 341px;

    @media screen and (max-width: $desktop-width) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: $desktop-width) {
  .section1, .section2, .section3, .section4, .section5, .section6, .section7, .section8 {
    margin-top: 24px !important;
  }
}